import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/ArrowBack.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/ArrowForward.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/CheckBox.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/CheckBoxOutlineBlank.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/Close.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/DarkMode.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/KeyboardArrowDown.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/LightMode.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/TrendingDown.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/TrendingUp.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/Group.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Button/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/CircularProgress/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Dialog/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/DialogContent/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/DialogTitle/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/IconButton/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Pagination/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Skeleton/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/app/src/features/Auth/components/MartinsTokenRefreshSession.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Auth/hooks/useUserNonce.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Auth/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/QueryClient/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Themes/lib/context.tsx");
import(/* webpackMode: "eager" */ "/app/src/features/Themes/lib/provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Card/elements.ts");
import(/* webpackMode: "eager" */ "/app/src/shared/components/ContentTitle/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/CustomTextField/Currency.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/ImageCropper/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Pagination/elements.ts");
import(/* webpackMode: "eager" */ "/app/src/shared/components/PlatformSelect/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/ProcessedName/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/SavingStatusBadge/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/SearchBox/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/StatusBadge/elements.ts");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Switches/DefaultSwitch.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/Table/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/shared/components/WeekSelect/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/anunciantes/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/campanhas/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/dashboard/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/form-campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/layout/Main.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/login/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/nova-campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios-banner/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios-campanha/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios-campanhas/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/relatorios/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/starter/index.tsx")